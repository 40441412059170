import React, { Component } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import HomeSlider from '../components/factoryGallerySlider'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image";

class PortfolioDetails extends Component {


    render() {
        return (
            <>
                <Header/>
                <StaticQuery
                    query={graphql`
                    query {
                        desktop: file(relativePath: { eq: "duynhat/factory/DSC_3762.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                        }
                        top: file(relativePath: { eq: "duynhat/company/DJI_0163-copy.jpg" }) {
                            childImageSharp {
                                fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        boxes: file(relativePath: { eq: "duynhat/DSC_7898.jpg" }) {
                            childImageSharp {
                                fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        emp: file(relativePath: { eq: "duynhat/factory/DSC_3553.jpg" }) {
                            childImageSharp {
                                fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                        poly: file(relativePath: { eq: "duynhat/factory/DSC_7885.jpg" }) {
                            childImageSharp {
                                fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    `}
                    render={data => {
                        return (
                <div class="page-content bg-white">
                    <BackgroundImage
                        // className="dlab-bnr-inr overlay-black-middle"
                        fluid={data.desktop.childImageSharp.fluid}
                        backgroundColor={`#d2151e`}
                        >
                        <div class="dlab-bnr-inr overlay-black-middle ">
                            <div class="container">
                                <div class="dlab-bnr-inr-entry">
                                    <h1 class="text-white">Facility Detail</h1>
                                    <div class="breadcrumb-row">
                                        <ul class="list-inline">
                                            <li><Link to="/">Home</Link></li>
                                            <li>Facility</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Facility Information</h2>
                                        <p>Duy Nhat Pack operates at newly opened 80,000m<sup>2</sup> facility located 
                                        in An Nghiep Industrial Park, Soc Trang.</p>
                                        <p>
                                        We are equipped with State-of-the Art printing machines: 
                                        roto-gravure printing up to 11-colouring stations, flexographic, 
                                        offet litho press, and complete line of packaging converting: 
                                        die-cutting, embossing,  creasing, gluing, bag-making. Besides, 
                                        our designing teams are so professional to ease customers’ needs 
                                        from making  new artwork, proofing until finished packages created 
                                        at customers’ satisfaction.
                                        </p>   
                                        <p>
                                        We have been implimenting modern quality assurance programs: 
                                        ISO 22000:2018 &amp; BRC for food packaging into our production
                                         activities  so as to minimize risks &amp; errors to products prior 
                                         delivering to customers. Yet, we keep innovating ourselves to a green 
                                         packaging industry by collaborating with World-renown plastic suppliers 
                                         to make our packaging eco-friendly, easy to recyle, downgauging  plastic
                                        wastes into environment.
                                        </p>

                                    
                                        <div class="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-user"></i>
                                                    <strong>Employees</strong> 500+
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-location-pin"></i>
                                                    <strong>LOCATION</strong>Soc Trang, Vietnam
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-ruler-alt-2"></i>
                                                    <strong>SIZE</strong>80,000m<sup>2</sup>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-medall "></i>
                                                    <strong>Standards</strong>ISO 9001:2005 &amp; BRC
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-stats-up "></i>
                                                    <strong>Experience</strong>16+ Years in Business
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div class="pro-details">
                                                    <i class="ti ti-settings "></i>
                                                    State of the Art Technology and Equipments
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-12 m-b30">
                                                <Img fluid={data.top.childImageSharp.fluid} alt="" className="img-cover"/>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <Img fluid={data.boxes.childImageSharp.fluid} alt="" className="img-cover"/>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <Img fluid={data.emp.childImageSharp.fluid} alt="" className="img-cover"/>
                                            </div>
                                            <div class="col-lg-12 m-b30">
                                                <Img fluid={data.poly.childImageSharp.fluid} alt="" className="img-cover"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                           
    
                           <HomeSlider 
                            //    data={homeSliderContentFactory}
                               contentWrapperCls="sliderStyle4"
                                />

                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
                }}
                />                
                <Footer/>                
            </>
        )
    }
}
export default PortfolioDetails;